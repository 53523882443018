body {
  background-color: rgb(1, 36, 15);
  color:aliceblue
}


.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  background-color: rgb(1, 36, 15);
}

.landing-logo {
  width: 40%;
  background-color: transparent; 
  border:none;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px solid black;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 20px; 
  margin-top: 100px; 
  background-color: rgb(1, 36, 15);
}

.top h1 {
  margin-top: 120px;
}

.landing-nombre {
  color: white;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px solid black;
  width: 90%;
  margin: 20px auto 10px auto; /* Ajusta el margen superior a 20px y el margen inferior a 10px */
  text-align: center;
}

.middle {
  margin-bottom: 30px;
  margin-top: 90px; /* Añade margen superior de 10px */
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
}


.middle a {
  font-size: 24px;
  text-decoration: none;
  color: white;
  position: relative;
  top: -10px;
}

.bottom {
  margin-top: 30px;
  color: white;
}

.bottom p {
  font-size: 18px;
  color: white;
}
