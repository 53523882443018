.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  margin-top: 60px;
  background-color: rgb(1, 36, 15);
  color:aliceblue;
}

.landing-logo {
  width: 20%;
  background-color: rgb(217, 240, 248);
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*border: 2px solid black;*/
  width: 90%;
  margin: 140px auto 0 auto; /* Ajusta el valor de margin-top según tus necesidades */
  text-align: center;
  padding-top: 20px;
  background-color: rgb(68, 168, 110);
  color:aliceblue;
}

.top img {
  /*border: 3px solid black;*/
  padding: 10px;
}

h1 {
  margin-top: 10px;
  color:aliceblue;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  color:aliceblue;
}

label {
  color:aliceblue;
  font-size: 20px;
  margin-bottom: 10px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  border: none;
}

button {
  background-color: rgb(1, 36, 15);
  color: rgb(253, 250, 250);
  font-size: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: rgb(1, 36, 15);
  color: white;
}

input[type="text"],
input[type="password"] {
  width: 60%; /* cambiar el ancho al valor deseado */
  height: 40px; /* cambiar la altura al valor deseado */
  margin-bottom: 20px;
  font-size: 16px; /* cambiar el tamaño de la fuente al valor deseado */
  padding: 5px; /* cambiar el padding al valor deseado */
  border-radius: 5px;
  border: none;
}